.our_services{
    position: relative;
}
.our_services .col-lg-4 h2{
    font-size: 1.3rem;
}
.our_services .col-lg-4 p{
    color: rgba(126, 126, 126, 0.911);
    margin: 1rem 2rem 5rem 2rem;
}
.service_title{
    color: #000000;
    padding-bottom: 1rem;
    border-bottom: 3px double #000000;
    text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.24);
    font-family: FreeMono, monospace;
    margin: 0% 20% 3% 20%;
}
.icon_size{
    font-size: 60px;
    margin-top: 15px;
    margin-bottom: 7px;
}
@media (max-width:768px){
    .service_title{
        font-size: 1rem;
    }
    .our_services .col-lg-4 p{
        font-size: 0.8rem;
     
    }
    .our_services .col-lg-4 h2{
        font-size: 1.1rem;
    }
    .service_title{
        margin: 0% 12% 3% 12%;
    }
    .our_services .col-lg-4 p{
        margin: 1rem 1rem 5rem 1rem;
    }
}
.partner-img img{
    width: 200px;
    height: 200px;
    margin: 1rem;
}
