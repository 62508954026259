/**********************************/
/*********** Top Bar CSS **********/
/**********************************/
.top-bar {
    padding: 0.5rem 0;
    background: #000000;
}
.margin_LeftRight{
    margin-left: 1rem;
    margin-right: 1rem;
}
.top-bar .tb-contact p {
    display: inline-block;
    margin: 0;
    color: gray;
}

.top-bar .tb-contact p i {
    margin-right: 0.5rem;
}

.top-bar .tb-contact p:first-child {
    margin-right: 1rem;
}

.top-bar .tb-menu {
    text-align: right;
}

.top-bar .tb-menu a, .top-bar .tb-contact p a {
    color: gray;
    font-size: 1rem;
    margin: 0 8px;
}

.top-bar .tb-contact p:hover,.top-bar .tb-menu a:hover, .top-bar .tb-contact p a:hover {
    color: #ffffff;
}

.top-bar .tb-menu a:last-child {
    margin-right: 0;
}

.top-bar .tb-menu a::after {
    position: absolute;
    content: "|";
    margin: 1px 0 0 6px;
    color: rgb(85, 85, 85);
    font-size: 1rem;
}

.top-bar .tb-menu a:last-child::after {
    display: none;
}



@media (max-width: 768px) {
    .top-bar .tb-contact,
    .top-bar .tb-menu {
        text-align: center;
    }

    .top-bar .tb-menu a, .top-bar .tb-contact p a {
        font-size: 0.8rem;
    }
    .top-bar .tb-contact p {
        font-size: 0.7rem;
    }
    .top-bar .tb-contact p a {
        margin: 0px;
    }
    .top-bar .tb-contact p:first-child {
        margin-right: 0.6rem;
    }
    .margin_LeftRight{
        margin-left: 0.1rem;
        margin-right: 0.1rem;
    }
}

