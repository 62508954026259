.content_back {
    background-color:rgba(0, 0, 0, 0.1);
    margin: 10px;
    border-radius: 10px;
}

.content_main{
    color:black;
    margin: 20px;
}
.detail_box_title {
    font-family: Verdana,sans-serif;
    color: rgba(10, 0, 99, 0.623);
    padding-top: 20px;
    font-weight: 700;
    text-align: center;
    display: block;
}
.info_description {
    font-family: Verdana,sans-serif;
    color:black;
    text-align: left;
}
.detail_box_subtitle {
    font-family: Verdana,sans-serif;
    font-size: 1rem;
    color: rgba(10, 0, 99, 0.623);
    font-weight: 700;
    text-decoration: none;
    text-align: left;
    margin: 20px;
}
@media (max-width:800px)
{
    .detail_box_title{
        font-size: 1rem;
    }
    .content_main{
        font-size: 0.8rem;
        margin: 10px;
    }
    .detail_box_subtitle{
        margin: 10px;
    }
}