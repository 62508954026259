/**********************************/
/********** Main News CSS *********/
/**********************************/
.main-news {
    position: relative;
    width: 100%;
    margin-bottom: 2rem;
    margin-top: 3rem;
}

/* @media (prefers-reduced-motion: no-preference) {
    .pic_animate{
        position: relative;
        animation-name: news_animate;
        animation-duration: 3s;
        animation-delay: 1s;
    }
    
} */

/* .heading_animate{
    
    animation-name: heading_animates;
    animation-duration: 3s;
    animation-delay: 1s;
} */
.main-news .col-md-4 {
    margin-bottom: 2rem;
}
.heading_CSS{
    margin-bottom: 2rem;
}
.main-news .mn-img {
    position: relative;
    overflow: hidden;
}

.main-news .mn-img img {
    width: 100%;
}

.main-news .mn-img:hover img {
    filter: blur(3px);
    -webkit-filter: blur(3px);
}

.main-news .mn-title {
    position: absolute;
    top: 0; 
    right: 0;
    bottom: 0; 
    left: 0;
    padding: 1rem 0.7rem 1rem 1.3rem;
    background-image: linear-gradient(rgba(0, 0, 0, .0), rgba(0, 0, 0, .3), rgba(0, 0, 0, .9));
    display: flex;
    align-items: flex-end;
}

.main-news .mn-title a {
    display: block;
    width: 100%;
    color: #ffffff;
    font-size: 1rem;
    font-weight: 400;
    transition: .3s;
}

.main-news .mn-title a:hover {
    color: #FFAF61;
}

.main-news .mn-list {
    margin-bottom: 2rem;
}

.main-news .mn-list h2, .main-news .col-lg-9 .heading_CSS{
    color: #000000;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 3px double #000000;
}

.main-news .mn-list ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.main-news .mn-list ul li {
    margin: 0 0 0.7rem 1.5rem; 
}

.main-news .mn-list ul li:last-child {
    margin-bottom: 0; 
}

.main-news .mn-list ul li a {
    color: #000000;
    display: block;
    line-height: 1.3rem;
}

/* .main-news .mn-list ul li::before {
    margin-left: 1rem;
    position: absolute;
    content: '\f061';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    color: #000000;
    left: 1px;
} */

.main-news .mn-list ul li a:hover {
    color: #FFAF61;
}

@media (max-width: 768px) {
    .main-news .mn-img{
        width: 80%;
        margin-left: 2rem;
    }  
    .heading_CSS{
        margin-left: 1rem;
        margin-right: 1rem;
        font-size: 1.4rem;
    }
    .main-news .mn-list {
        margin-left: 1rem;
        margin-right: 1rem;
    }
    .main-news .mn-list ul li::before {
        margin-left: 2rem;
    }
    .read_more_list{
        font-size: 0.8rem !important;
    }
}
@keyframes news_animate {
    0%{left: -1200px;}
    100%{left: 0px;}
}

@keyframes heading_animates {
    0%{font-size: 0%;}
    100%{font-size: 100%;}
}