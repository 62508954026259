/**********************************/
/********** Top News CSS **********/
/**********************************/
/* .top-news {
    position: relative;
    width: 100%;
    padding: 30px 0 0 0;
}

.top-news .tn-img {
    position: relative;
    overflow: hidden;
} */
/* .colorrs{
    box-shadow: 15px 10px 20px #888888;
} */

/* .slider{
    position: relative;
    animation-name: slider_animate;
    animation-duration: 3s;
    animation-delay: 1s;
} */
.tn-img img {
    width: 100%;
}
/* 
.tn-img:hover img {
    filter: blur(3px);
    -webkit-filter: blur(3px);
} */

/* .top-news .tn-title {
    position: absolute;
    top: 0; 
    right: 0;
    bottom: 0; 
    left: 0;
    padding: 15px 10px 15px 20px;
    background-image: linear-gradient(rgba(0, 0, 0, .0), rgba(0, 0, 0, .3), rgba(0, 0, 0, .9));
    display: flex;
    align-items: flex-end;
}

.top-news .tn-title a {
    display: block;
    width: 100%;
    color: #ffffff;
    font-size: 18px;
    font-weight: 400;
    transition: .3s;
} */

/* .top-news .tn-left .tn-title a {
    font-size: 30px;
    font-weight: 600;
}

.top-news .tn-title a:hover {
    color: #FFAF61;
}

.top-news .tn-left {
    margin-bottom: 30px;
}

.top-news .tn-right {
    margin-bottom: 60px;
    padding: 0 30px;
}

.top-news .tn-right .col-md-6 {
    padding: 0;
}

.top-news .slick-prev,
.top-news .slick-next {
    width: 28px;
    height: 28px;
    z-index: 1;
    transition: .5s;
    color: #ffffff;
    background: #000000;
    border-radius: 4px;
}

.top-news .slick-prev {
    left: 30px;
}

.top-news .slick-next {
    right: 30px;
}

.top-news .slick-prev:hover,
.top-news .slick-prev:focus,
.top-news .slick-next:hover,
.top-news .slick-next:focus {
    color: #ffffff;
    background: #FFAF61;
}

.top-news .slick-prev::before,
.top-news .slick-next::before {
    font-family: "Font Awesome 5 Free";
    font-size: 18px;
    font-weight: 900;
    color: #ffffff;
}

.top-news .slick-prev::before {
    content: "\f104";
}

.top-news .slick-next::before {
    content: "\f105";
} */
/* @keyframes slider_animate {
    0%{left: -100%;}
    50%{left: 0%;}
    60%{left:-5%}
    70%{left: 0%;}
    78%{left: -3%;}
    85%{left: 0%;}
    93%{left: -1%;}
    100%{left: 0%;}
} */