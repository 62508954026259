/**********************************/
/********** Tab News CSS **********/
/**********************************/
.tab-news {
    position: relative;
}
/* .left_part{
    position: relative;
    animation-name: left_part_animate;
    animation-duration: 3s;
    animation-delay: 1s;
}
.right_part{
    position: relative;
    animation-name: right_part_animate;
    animation-duration: 3s;
    animation-delay: 1s;
} */
.tab-news .col-md-6 {
    margin-bottom: 4rem;
}
.tab-news .col-md-6 .tab-content .tn-news .tn-title b{
    font-size: 1rem;
}

.tab-news .nav.nav-pills .nav-link {
    color: #ffffff;
    background: #000000;
    border-radius: 0;
}

.tab-news .nav.nav-pills .nav-link:hover,
.tab-news .nav.nav-pills .nav-link.active {
    color: #ffffff;
    background: #FFAF61;
}

.tab-news .tab-content {
    padding: 2rem 0.7rem 0.7rem 0.7rem;
    background: rgba(0, 0, 0, .05);
}

.tab-news .tn-news {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    background: #ffffff;
}
.tab-news .tn-img img {
    width: 100%;
    height: 100%;
}
.tab-news .tn-img{
    padding: 0rem;
}
.tab-news .tn-title {
    padding: 0.7rem 1rem;
}

.tab-news .tn-title a {
    color: #000000;
    font-size: 1.2rem;
    /* font-weight: 400; */
    transition: all .3s;
}

.tab-news .tn-title a:hover {
    color: #FFAF61;
}

@media (max-width: 768px) {
    .tab-news .col-md-6 .nav .nav-item a{
        font-size: 0.8em;
    }
    .tab-news .col-md-6 .tab-content .tn-news .tn-title b{
        font-size: 0.8rem;
    }
    .tab-news .col-md-6 {
        width: 100%;
    }
    .style_data{
        font-size: 0.7rem;
    }
    .tab-news .tn-title {
        padding: 0.4rem 0.2rem;
    }
    
}

/* @keyframes left_part_animate {
    0%{left: -800px;}
    100%{left: 0px;}
}
@keyframes right_part_animate {
    0%{left: 800px;}
    100%{left: 0px;}
} */