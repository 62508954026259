.social_analytic{
    position:relative;
    margin: 50px 0px 80px 0px;    
}
.columns_css{
    padding: 3rem 3rem 3rem 3rem;
    width: 100%;
    margin: 0rem 1rem 1rem 0rem;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 10px 0px rgba(199, 199, 199, 0.3);
}

.columns_css:hover{
    box-shadow: 0px 0px 10px 1px rgba(100, 100, 100, 0.4);
}
.test_css{
    font-size: 1.2rem;
    font-family: FreeMono, monospace;
    text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
}

.img-css_creative{
    width: 10rem !important;
    height: 10rem;
    margin-bottom: 1.3rem;
}
.logo-css{
    width: 10rem !important;
    height: 6rem;
    margin-bottom: 1.3rem;
}
.bottom_line{
    color: #000000;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 3px double #000000;
    text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.24);
    font-family: FreeMono, monospace;
    font-size: 2rem;
}
.title_head{
    margin: 3% 25% 3% 25%;
}
.social_text_css{
    color: rgba(83, 83, 83, 0.849);
}
@media (max-width:768px){
    .title_head{
        margin: 0rem 2rem 2rem 2rem;
        font-size: 1rem;
    }
    .test_css{
        font-size: 0.8rem;
    }
    .logo-css{
        width: 5rem !important;
        height: 3rem;
        margin-bottom: 1.3rem;
    }
    .title_head_community{
        margin: 2rem 4rem 2rem 4rem !important;
    }
    .social_title_size{
        font-size: 1.1rem;
    }
    .social_text_css{
        font-size: 1rem;
    }
    .social_head_css{
        font-size: 1rem;
    }
    .img-css_creative{
        width: 15rem !important;
        height: 15rem;
        margin-bottom: 0rem;
    }
    .padding_vision{
        padding: 3rem 1rem 3rem 1rem !important;
    }
 
}