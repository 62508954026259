/**********************************/
/********** General CSS ***********/
/**********************************/
body {
    color: #000000;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    background: #ffffff;
}

a {
    color: #FFAF61;
    transition: 0.5s;
}

a:hover,
a:active,
a:focus {
    color: #000000;
    outline: none;
    text-decoration: none;
}

p {
    padding: 0;
    margin: 0 0 15px 0;
    color: #000000;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0 0 15px 0;
    padding: 0;
    color: #000000;
    font-weight: 600;
}

h4,
h5,
h6 {
    font-weight: 400;
}

/* .back-to-top {
    position: fixed;
    display: none;
    background: #FFAF61;
    color: #ffffff;
    width: 35px;
    height: 35px;
    text-align: center;
    line-height: 1;
    font-size: 16px;
    right: 15px;
    bottom: 15px;
    transition: background 0.5s;
    border-radius: 4px;
    z-index: 11;
}

.back-to-top:hover {
    background: #000000;
}

.back-to-top i {
    padding-top: 9px;
    color: #ffffff;
} */


/**********************************/
/********* Form Input CSS *********/
/**********************************/
.form-control,
.custom-select {
    width: 100%;
    height: 35px;
    padding: 5px 15px;
    color: #999999;
    border: 1px solid #cccccc;
    margin-bottom: 15px
}

.form-control:focus,
.custom-select:focus {
    box-shadow: none;
    border-color: #6363637a;
}

.custom-radio,
.custom-checkbox {
    margin-bottom: 15px;
}

.custom-radio .custom-control-label::before,
.custom-checkbox .custom-control-label::before {
    border-color: #cccccc;
}

.custom-radio .custom-control-input:checked~.custom-control-label::before,
.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    color: #ffffff;
    background: #6363637a;
}

button.btn {
    color: #ffffff;
    background: #000000;
    border: 1px solid #000000;
}

button.btn:hover {
    color: #ffffff;
    background: #6363637a;
    border-color: #6363637a;
}
/**********************************/
/********** Contact CSS ***********/
/**********************************/
.contact {
    position: relative;
    padding: 25px 0 15px 0;
}

.contact .contact-form,
.contact .col-md-4 .contact-info {
    margin-bottom: 45px;
}

.contact .col-md-4 .contact-info h4 {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 400;
}

.contact .col-md-4 .contact-info h4 i {
    color: #000000;
    margin-right: 5px;
}

.contact .col-md-4 .contact-info .social {
    position: relative;
    width: 100%;
}

.contact .col-md-4 .contact-info .social a {
    display: inline-block;
    margin: 10px 10px 0 0;
    width: 40px;
    height: 40px;
    padding: 7px 0;
    text-align: center;
    font-size: 18px;
    border: 1px solid #000000;
    border-radius: 4px;
}
#textarea{
    margin-left: 10px;
}


@media (max-width:800px)
{
    .contact .col-md-4 .contact-info .social a {
        width: 30px;
        height: 30px;
        padding: 3px 0;
        font-size: 15px;
        
    }
   
}

