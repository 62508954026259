/**********************************/
/*********** Brand CSS ************/
/**********************************/
.brand {
    padding: 0.6rem 0;
    background: #ffffff;
    position: relative;
    /* animation-name: brand_animate;
    animation-duration: 2s;
    animation-delay: 1s; */
}

.brand_LeftRight{
    margin-left: 4rem;
    margin-right: 4rem;
}

.brand .b-logo {
    overflow: hidden;
}

.brand .b-logo a img {
    max-width: 100%;
    max-height: 5rem;
}



/* .brand .b-ads {
    overflow: hidden;
}

.brand .b-ads a img {
    max-width: 100%;
    max-height: 100%;
}

.brand .b-search {
    width: 100%;
} */

/* .brand .b-search input[type=text] {
    width: 100%;
    height: 40px;
    padding: 0 15px;
    color: #666666;
    border: 1px solid #cccccc;
    border-radius: 4px;
} */

/* .brand .b-search input[type=text]:focus {
    border-color: #FFAF61;
} */

/* .brand .b-search button {
    position: absolute;
    width: 40px;
    height: 40px;
    top: 0;
    right: 15px;
    border: none;
    background: none;
    color: #FFAF61;
    border-radius: 0 4px 4px 0; 
}

.brand .b-search button:hover {
    background: #FFAF61;
    color: #ffffff;
} */

@media (min-width: 768px) {
    .brand {
        max-height: 6rem;
    }
}

@media (max-width: 768px) {
    .brand .b-logo,
    .brand .b-ads,
    .brand .b-search {
        text-align: center;
    }
    .brand .b-logo a img {
        max-width: 100%;
        max-height: 2.5rem;
    }
    .title_brand{
        font-size: 1rem;
    }
    /* .brand .b-search {
        margin-bottom: 0;
    } */
}

