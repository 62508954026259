.textShadow{
    text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.15);
    font-family: FreeMono, monospace;
    margin-bottom: 3rem;
    margin-top: 2rem;
}
.Vhead{
    color: #000000;
    padding-bottom: 1rem;
    border-bottom: 3px double #000000;
    margin: 0rem 8rem 1rem 8rem;
}
.fontSize1{
    font-size: 0.9rem;
}
.notes{
    color: red;
    font-size: 0.8rem;
}
.T3scrims{
    position: relative;
    text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.15);
    font-family: FreeMono, monospace;
    margin: 1rem 0rem 3rem 0rem;
}
.boxScrimsCard{
    padding: 2rem 1rem 1rem 1rem ;
    margin: 1rem 0rem 1rem 0rem;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 10px 0px rgba(199, 199, 199, 0.3);
}
.boxScrimsCard:hover{
    box-shadow: 0px 0px 10px 1px rgba(100, 100, 100, 0.4);
}
.sectionMargin{
    margin: 1rem 0rem 1rem 0rem;
}

@media (max-width:800px)
{
    .Vhead{
        margin: 0rem 1rem 0.4rem 1rem;
        font-size: 1.2rem;
    }
    .fontSize{
        font-size: 0.9rem;
    }
    .fontSize1{
        font-size: 0.7rem;
    }
    .notes{
        font-size: 0.6rem;
    }
}